export enum CorporateFormModes {
  Registration = 'Registration',
  Details = 'Details',
  Success = 'Success',
}

export enum ParticipantsFormModes {
  Registration = 'Registration',
  Payment = 'Payment',
  Success = 'Success',
}

export enum PreSpeakerFormModes {
  Registration = 'Registration',
  Success = 'Success',
}
