import axios, { Method, AxiosRequestConfig } from 'axios';
import { toastStore } from 'stores';

class HTTPService {
  private readonly basePath: string;
  private readonly host: string = (process.env.REACT_APP_HOST || window.location.origin) + '/api';

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  /**
   * запрос
   * @url - путь
   * @method - метод
   * @body - тело запроса
   */
  public async apiRequest<T>(url: string, method: Method, body?: any, options: AxiosRequestConfig = {}): Promise<T> {
    options.headers = { 'Content-type': 'application/json' };

    return axios
      .request({ baseURL: this.host, url, method, data: body, ...options })
      .then((response) => {
        return response.data;
      })
      .catch((errorData) => {
        if (axios.isCancel(errorData)) {
          console.log(errorData);
          return;
        }

        if (!errorData.response) return;

        if (errorData.code === 'ERR_NETWORK') {
          toastStore.showError('Ошибка соединения - сервер не отвечает. Повторите запрос чуть позже.');
          return;
        }

        console.log(errorData); //'Что-то пошло не так...'

        const { data } = errorData.response;

        if (!!data.error?.message) toastStore.showError(data.error.message);
        else toastStore.showError();

        throw data;
      });
  }

  public GET<T>(path: string): Promise<T> {
    return this.apiRequest(`${this.basePath}${path}`, 'get');
  }

  public POST<T>(path: string, body: any): Promise<T> {
    return this.apiRequest(`${this.basePath}${path}`, 'post', body);
  }

  /**
   * PUT запрос
   *
   * @param {string} path - Api путь
   * @param data {object} - данные для передачи
   * @param {object} options - Параметры запроса
   */
  public PUT<T>(path: string, body?: any, options?: AxiosRequestConfig): Promise<T> {
    return this.apiRequest(`${this.basePath}/${path}`, 'put', body, options);
  }
}

export default HTTPService;
