import { IPerkDto, IRewiewDto, IOrganizerDto } from 'shared/interfaces';

/* Сервис для получения данных секции Лэндинга - старые, которые могут еще появиться*/
class LandingService {
  public getPerks(): Promise<IPerkDto[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const result: IPerkDto[] = [
          {
            id: 1,
            name: 'Спикеры из Tesla, Netflix, Wrike',
            iconPath: 'https://devpro.blob.core.windows.net/images/perk-04.png',
            order: 1,
            locale: 'ru',
          },
          {
            id: 2,
            name: 'Ламповые беседы',
            iconPath: 'https://devpro.blob.core.windows.net/images/perk-06.png',
            order: 2,
            locale: 'ru',
          },
          {
            id: 3,
            name: 'Afterparty',
            iconPath: 'https://devpro.blob.core.windows.net/images/perk-05.png',
            order: 3,
            locale: 'ru',
          },
          {
            id: 4,
            name: 'Музей компьютерной техники',
            iconPath: 'https://devpro.blob.core.windows.net/images/perk-02.png',
            order: 4,
            locale: 'ru',
          },
        ];
        resolve(result);
      }, 500);
    });
  }

  public getRewiews(): Promise<IRewiewDto[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const result: IRewiewDto[] = [
          {
            id: 1,
            text: 'Мне в целом понравилось, как было организовано мероприятие. Все доклады были синхронизированы – не было такого, что в одной аудитории доклад ещё не успел закончится, а в другой уже начался. Для конференции создали удобное мобильное приложение, с помощью которого можно было максимально быстро сориентироваться и пойти на тот доклад, который был полезным и интересным. В конце выступления спикера можно было получить ответы на свои вопросы. А также оценить доклад по пятибалльной шкале.',
            author: 'Андрей Юрлов',
            authorPhoto: 'https://devpro.blob.core.windows.net/photos/reviews/yrlov.jpg',
            location: 'Новосибирск',
            company: 'Teamlead ELTEX',
            isVisible: true,
            order: 1,
            locale: 'ru',
          },
          {
            id: 2,
            text: 'I was impressed but the number of participants and how lively the event was. Clemence and Roman got many questions after their presentation and Clemence had the opportunity to demo, in details, her TJBot to some developers.',
            author: 'Clemence Lebrun',
            authorPhoto: 'https://devpro.blob.core.windows.net/photos/reviews/Lebrun.jpg',
            location: 'France',
            company: 'Developer Advocate IBM Europe',
            isVisible: true,
            order: 2,
            locale: 'en',
          },
          {
            id: 3,
            text: 'Крутая конференция, которая собрала более 700 участников! Из впечатлений: длинные очереди на регистрацию – это не круто, но они того стоили, атмосфера классная. Несколько направлений, лекции по разработке и просто трендам в области Digital. Запомнился доклад про Техпиар от представителя Avito, наверное, потому что техпиар – это как раз то, чем я, отчасти, занимаюсь.',
            author: 'Александр Кокоулин',
            authorPhoto: 'https://devpro.blob.core.windows.net/photos/reviews/kokoulin.jpg',
            location: 'Кемерово',
            company: 'Event-менеджер Digital-агентства Атвинта',
            isVisible: true,
            order: 3,
            locale: 'ru',
          },
          {
            id: 4,
            text: 'Томску нужно побольше таких мероприятий, на которых собираются единомышленники из разных ИТ-Компаний. Здесь происходит обмен опытом, новые знакомства, появляется возможность услышать альтернативную точку зрения на привычные вещи, узнать о разработках и направлениях деятельности других ИТ-Компаний Томска, это ценно. Спасибо Rubius за создание такой отличной традиции для ИТ-сообщества Томска!',
            author: 'Ирина Залозных',
            authorPhoto: 'https://devpro.blob.core.windows.net/photos/reviews/zaloznih.jpg',
            location: 'Томск',
            company: 'HR SIDENIS',
            isVisible: true,
            order: 4,
            locale: 'ru',
          },
          {
            id: 5,
            text: 'Для нашей команды конференция прошла насыщенно – в общей сложности мы прослушали более 20 интереснейших докладов, посетили митапы, обсудили злободневные темы с разработчиками из других компаний, выиграли нужные в работе лицензии от JetBrains и Parallels и, конечно же, сходили на afterparty. Мероприятие было организовано на высоком уровне, спасибо DevPRO, что вытащили нас из вакуума и дали поделиться опытом с коллегами по цеху. Было круто и ждем DevPRO’21!',
            author: 'Команда Atomiq',
            authorPhoto: 'https://devpro.blob.core.windows.net/photos/reviews/atomiq.jpg',
            location: 'Томск',
            company: '',
            isVisible: true,
            order: 5,
            locale: 'ru',
          },
          {
            id: 6,
            text: 'Меня впечатлил режим Live Demo – программирование прямо сейчас, как на открытой операции. Очень живой доклад получился у представителей Interweb – практические советы командам, в которых нет своего дизайнера или специалиста по UX/UI, и как всегда нет времени. К сожалению, я не была на докладе Сергея Ошаева, но мне кажется, что я его уже слышала и во многом согласна с ним. Ещё меня зацепила цель конференции – превратить Томск в Кремниевую долину. Удачи ребятам в её достижении!',
            author: 'Маргарита Суворова',
            authorPhoto: 'https://devpro.blob.core.windows.net/photos/reviews/margo.jpg',
            location: 'Томск',
            company: '«Элком+»',
            isVisible: true,
            order: 6,
            locale: 'ru',
          },
          {
            id: 7,
            text: 'В связи с небольшой дезориентацией я забыл сказать самое главное. Спасибо. Спасибо Вам за то, что вы делаете! Меня давно мучал вопрос: что дальше, чем я хочу заниматься? И именно благодаря конференции и позитивно заряженным людям у меня больше нет сомнений куда я направлю свой жизненный курс. Пока я ехал домой я серьёзно пересмотрел своё отношение к миру и людям. Вы сказали, что одно сказанное слово может изменить многое. Так и есть. Спасибо!',
            author: 'Александр Мигутский',
            authorPhoto: 'https://devpro.blob.core.windows.net/photos/reviews/migutsky.jpg',
            location: 'Кемерово',
            company: '',
            isVisible: true,
            order: 7,
            locale: 'ru',
          },
        ];
        resolve(result);
      }, 500);
    });
  }

  public getOrganizers(): Promise<IOrganizerDto[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const result: IOrganizerDto[] = [
          {
            id: 1,
            iconSrc: 'https://devpro.blob.core.windows.net/logos/rubius-logo.svg',
            link: 'https://rubius.com/',
            order: 2,
            isVisible: true,
          },
          {
            id: 2,
            iconSrc: 'https://devpro.blob.core.windows.net/logos/ra-logo.svg',
            link: 'https://academy.rubius.com/',
            order: 1,
            isVisible: true,
          },
        ];

        resolve(result);
      }, 500);
    });
  }
}

export default new LandingService();
