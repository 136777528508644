export enum ToastMessageTypes {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

export enum MenuKeys {
  About = 'About',
  Events = 'Events',
  Program = 'Program',
  Partners = 'Partners',
  Price = 'Price',
}

export const KeyCodes = {
  ALT: 18,
  SHIFT: 16,
  CTRL: 17,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  DELETE: 46,
};

export enum PaymentStatuses {
  Free = 'free',
  Failed = 'failed',
  Success = 'success',
  Pending = 'pending',
  Rubius = 'rubius',
}

export enum SuccessDialogKeys {
  Speaker = 'Speaker',
  Participant = 'Participant',
  Rubius_Employee = 'Rubius_Employee',
  Corporate = 'Corporate',
}
